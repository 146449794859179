import _trim from 'lodash/trim';

export function truncate(str: string, n = 20) {
  return str.length > n ? str.substring(0, n - 1) + '...' : str;
}

export function truncateFromStart(str: string, n = 20) {
  const length = str.length;
  return length > n ? '...' + str.substring(length - n) : str;
}

export function truncateMiddle(str: string, n = 20) {
  const length = str.length;
  const start = str.substring(0, n / 2);
  const end = str.substring(length - n / 2);
  return length > n ? `${start}..${end}` : str;
}

export function capitalize(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

export function getNameFromEmail(str: string) {
  if (typeof str !== 'string') {
    return null;
  }
  return str.split('@')[0];
}

export function startsWith(str: string, x: string) {
  return !!x && x.length > 0 && str.indexOf(x) === 0;
}

export function isValidIp(value: string) {
  // it will fail any string with a newline
  const ipRegex =
    /((?!.*\n)^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$)/gm;
  return ipRegex.test(value);
}

export const maybePlural = (value: number, label: string) => (Math.abs(value) === 1 ? label : `${label}s`);

/**
 * generate random string of length 16
 */
export const generateRandomId = () => {
  return `${Math.random().toString(36).substring(2, 10)}${Math.random().toString(36).substring(2, 10)}`.padStart(
    16,
    'm',
  );
};

export const encodeKeyForRecharts = (key: string | number | undefined) => {
  return String(key).replace(/\./g, '^-^');
};

export const decodeKeyForRecharts = (key: string) => {
  return String(key).replace(/\^-\^/g, '.');
};

export const toLowercaseAlphanumeric = (str: string) => {
  return _trim(str.replace(/[^a-z0-9]+/gi, '-').toLowerCase(), '-');
};
